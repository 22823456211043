<template>
  <b-overlay
    :show="showOver"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <validation-observer ref="simpleRules">
      <div>
        <div class="card">
          <div class="d-lg-flex justify-content-between align-items-center px-2 py-1">
            <h3 class="font-weight-bolder">
              {{ $t('runTrack') }}
            </h3>

            <div class="d-flex">
              <div class="income-order-search order-search w-100">
                <div class="anan-input search-input">
                  <div class="anan-input__inner anan-input__inner--normal">
                    <input
                      v-model="search_val"
                      type="text"
                      :placeholder="$t('search')"
                      class="anan-input__input"
                      @keyup.enter="getData(search_val)"
                    >
                    <div class="anan-input__suffix">
                      <button
                        type="button"
                        class="anan-button anan-button--normal anan-button--primary"
                        @click="getData(search_val)"
                      >
                        <i class="fal fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <b-form-select
                v-model="selected"
                :options="typeOpsearch"
                class="w-50 ml-1"
                size="sm"
              />
              {{ selected }} -->
            </div>
          </div>
          <div class="d-lg-flex justify-content-between align-items-center">
            <div class="px-2">
              <button
                type="button"
                class="anan-button--xl-large anan-button anan-button--primary anan-button--normal"
                @click="showAddNew()"
              >
                <span>+ {{ $t('addInfo') }} </span>
              </button>
            </div>
            <div class="px-2">
              <span
                style="font-size: 11px;"
                :class="`badge mr-1 ${runtrack_status ? 'badge-success': 'badge-danger'}`"
              >{{ runtrack_status ? $t('activate') : $t('key-328') }}</span>
              <button
                type="button"
                class="anan-button--xl-large anan-button anan-button--primary anan-button--normal"
                @click="downloadProgram()"
              >
                <span> Download program</span>
              </button>
            </div>

          </div>
          <div class="pl-2 pr-2 pt-1">
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :items="items"
              :fields="fields"
              show-empty
            >
              <template #empty>
                <div class="text-center">
                  <img
                    src="@/assets/images/anan-img/empty/empty.png"
                    alt="empty"
                    width="180px"
                  >
                  <p>No Data</p>
                </div>
              </template>
              <!-- :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn" -->
              <template #cell(number)="data">
                {{ perPage * (currentPage - 1) + (data.index + 1) }}
              </template>

              <template #cell(actions)="data">
                <feather-icon
                  icon="Edit3Icon"
                  class="mr-1 text-primary cursor-pointer"
                  @click="showAddNew(data.item)"
                />
                <feather-icon
                  class="mr-1 text-danger cursor-pointer"
                  icon="TrashIcon"
                  @click="DeleteData(data.item)"
                />
                <feather-icon
                  v-if="runtrack_status"
                  icon="PlayIcon"
                  class="mr-1 text-primary cursor-pointer"
                  @click="RunTrack(data.item)"
                />
              </template>
            </b-table>

            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="getData(search_val)"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </div>

        <b-modal
          id="modal-runtrack-detail"
          size="xl"
          hide-footer
          hide-header
        >
          <b-overlay
            :show="showOverOder"
            variant="transparent"
            opacity="0.99"
            blur="5px"
            rounded="sm"
          >
            <template #overlay>
              <div class="text-center">
                <p id="cancel-label">
                  กรุณารอสักครู่...
                </p>
              </div>
            </template>
            <b-row>
              <b-col cols="3">
                <div class="card p-1">
                  <div class="d-flex justify-content-between">
                    <div>
                      <h3>
                        {{ $t('orderList') }}

                        <!-- <feather-icon
                          icon="RefreshCcwIcon"
                          size="22"
                          class="cursor-pointer ml-1"
                          @click="reloadall()"
                        /> -->
                      </h3>
                    </div>
                    <div>
                      {{ $t('all') }}
                      <b-badge
                        variant="primary"
                        pill
                      >
                        {{ orderListnew.length > 0 ? orderListnew.length : 0 }}
                      </b-badge>
                    </div>
                  </div>
                  <hr>
                  <div style="overflow-y: auto; max-height: 600px !important">
                    <b-table
                      class="text-center"
                      :items="orderListnew"
                      :fields="orderListfields"
                    >
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(orderID)="data">
                        {{ data.item.orderID }}
                      </template>
                    </b-table>
                  </div>
                </div>
              </b-col>

              <b-col cols="9">
                <div class="card p-1">
                  <div class="d-flex justify-content-between">
                    <div>
                      <h3>
                        {{ $t('key-220') }}
                        <!-- <feather-icon
                          icon="RefreshCcwIcon"
                          size="22"
                          class="cursor-pointer ml-1"
                          @click="reloadall()"
                        /> -->
                      </h3>
                    </div>
                    <div>
                      {{ $t('all') }}
                      <b-badge
                        variant="primary"
                        class="mr-2"
                        pill
                      >
                        {{ NewOrderList.length > 0 ? NewOrderList.length : 0 }}
                      </b-badge>
                      {{ $t('key-177') }}
                      <b-badge
                        variant="success"
                        class="mr-2"
                        pill
                      >
                        {{
                          NewOrderList.reduce(
                            (acc, ele) => acc + (ele.tag_see ? 1 : 0),
                            0
                          )
                        }}
                      </b-badge>
                      {{ $t('key-221') }}
                      <b-badge
                        variant="danger"
                        pill
                      >
                        {{
                          NewOrderList.reduce(
                            (acc, ele) => acc + (!ele.tag_see ? 1 : 0),
                            0
                          )
                        }}
                      </b-badge>
                      <button
                        type="button"
                        class="anan-button--xl-large anan-button anan-button--primary anan-button--normal ml-1"
                        @click="$bvModal.hide('modal-runtrack-detail')"
                      >
                        <span> {{ $t('key-214') }}</span>
                      </button>
                    </div>
                  </div>
                  <hr>
                  <div style="overflow-y: auto; max-height: 600px !important">
                    <b-table
                      class="text-center"
                      :items="NewOrderList"
                      :fields="NewOrderListfields"
                    >
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(orderID)="data">
                        {{ data.item.orderID }}
                      </template>
                      <template #cell(tag_see)="data">
                        <div v-if="data.item.tag_see">
                          <b-badge
                            v-b-toggle="'collapse-' + data.index"
                            variant="success"
                            pill
                            class="cursor-pointer"
                          >
                            <feather-icon icon="CheckIcon" /> {{ $t('track') }}
                          </b-badge>
                          <b-collapse
                            :id="'collapse-' + data.index"
                            class="mt-1"
                          >
                            <div class="d-flex justify-content-center align-items-center text-center">
                              <b-row>
                                <b-col
                                  v-if="Array.isArray(data.item.tag_id)"
                                  cols="4"
                                >
                                  <div
                                    v-for="(track, index) in data.item.tag_id"
                                    :key="index"
                                  >
                                    <b-badge
                                      variant="primary gradient"
                                      pill
                                      class="ml-1"
                                    >
                                      <feather-icon icon="BoxIcon" />
                                      {{ track }}
                                    </b-badge>
                                  <!--  -->
                                  </div>
                                </b-col>
                                <b-col
                                  v-else
                                  cols="4"
                                >
                                  <div>
                                    <b-badge
                                      variant="primary gradient"
                                      pill
                                      class="ml-1"
                                    >
                                      <feather-icon icon="BoxIcon" />
                                      {{ data.item.tag_id }}
                                    </b-badge>
                                  <!--  -->
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </b-collapse>
                        </div>
                        <div v-else>
                          <b-badge
                            variant="danger"
                            pill
                          >
                            <feather-icon icon="XIcon" /> {{ $t('track-0') }}
                          </b-badge>
                        </div>
                      </template>
                    </b-table>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-overlay>
        </b-modal>

        <b-modal
          id="modal-add-new"
          :title="$t('addInfo')"
          :ok-title="$t('save')"
          :cancel-title="$t('cancel')"
          @ok.prevent="submitAddNew()"
        >
          <validation-observer ref="addNewUser">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="username"
                rules="required"
              >
                <label for="username">Username : <span class="text-danger">*</span></label>
                <b-form-input
                  id="username"
                  v-model="username"
                  type="text"
                  placeholder="username"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="password"
                rules="required"
              >
                <label for="password">Password : <span class="text-danger">*</span></label>
                <b-form-input
                  id="password"
                  v-model="password"
                  type="text"
                  placeholder="password"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Type"
                rules="required"
              >
                <label for="type">Website : <span class="text-danger">*</span></label>
                <b-form-select
                  v-model="typeweb"
                  :options="typeOp"
                  :state="errors.length > 0 ? false : null"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </validation-observer>
        </b-modal>

        <b-modal
          id="overlay-modal"
          centered
          hide-footer
          hide-header
          no-close-on-backdrop="true"
          no-close-on-esc="true"
        >
          <div class="text-center py-4">
            <b-spinner
              variant="primary"
              label="Spinning"
            />
            <p class="mt-2 font-weight-bolder">
              {{ popupMessage }}
            </p>
          </div>
        </b-modal>
      </div>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BTable,
  BLink,
  BFormSelect,
  BModal,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BPagination,
  BOverlay,
  BRow,
  BCol,
  BBadge, // BIcon,
  BCollapse,
  BCard,
  VBToggle,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import moment from 'moment-timezone'

export default {
  components: {
    BTable,
    BLink,
    BFormSelect,
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BPagination,
    vSelect,
    BOverlay,
    BRow,
    BCol,
    BBadge,
    // BIcon,
    BCollapse,
    BCard,
    BSpinner,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      isActive: 'all',
      required,
      selected: null,
      amount: 0,
      detail: '',
      type: '',
      customerCode: '',
      prefix: '',
      prefixNum: '',
      search: '',
      options: [
        { value: null, text: 'ทั้งหมด' },
        { value: 1, text: 'รถ' },
        { value: 2, text: 'เรือ' },
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 10,
      totalRows: 1,

      typeOp: [
        { value: 1, text: '1688' },
        { value: 2, text: 'Taobao, Tmall' },
      ],
      typeOpsearch: [
        { values: '', text: 'ทั้งหมด' },
        { values: 1, text: 'เติมเงิน' },
        { values: 2, text: 'ถอนเงิน' },
        { values: 3, text: 'ชำระค่านำเข้า' },
        { values: 4, text: 'ค่าสินค้า' },
        { values: 5, text: 'ส่วนต่างค่าขนส่งในไทย' },
        { values: 6, text: 'ค่าสินค้าใน MALL' },
        { values: 7, text: 'ฝากจ่ายเถาเปา' },
        { values: 8, text: 'ค่า Commission' },
        { values: 9, text: 'เติมเงินอาลีเพย์' },
        { values: 10, text: 'โอนเงินไปจีน' },
        { values: 11, text: 'แจ้งไม่ได้รับสินค้า' },
        { values: 12, text: 'แจ้งร้านค้าคืนเงิน' },
        { values: 13, text: 'ค่าจัดส่งในไทย' },
        { values: 14, text: 'แลกเงินหยวนเป็นเงินบาท' },
        { values: 15, text: 'ส่งของไปจีน' },
        { values: 16, text: 'แลกแต้ม' },
        { values: 17, text: 'อื่นๆ' },
      ],
      items: [],
      showOver: false,
      cuslist: [],
      id: null,
      username_cus: '',
      prefixnumber: '',
      search_val: null,
      typeweb: null,
      username: null,
      password: null,
      addotp: null,
      ref_id: null,
      type_otp: null,
      username_otp: null,

      orderListfields: [
        {
          key: 'index',
          label: '#',
          thStyle: { width: '5px' },
          tdClass: 'text-muted',
        },
        {
          key: 'orderID',
          label: 'เลขใบสั่งซื้อ',
          thStyle: { width: '100%' },
        },
      ],

      NewOrderListfields: [
        {
          key: 'index',
          label: '#',
          thStyle: { width: '5%' },
          tdClass: 'text-muted',
        },
        {
          key: 'orderID',
          label: 'เลขใบสั่งซื้อ',
          thStyle: { width: '20%' },
        },
        {
          key: 'tag_see',
          label: 'สถานะ',
          thStyle: { width: '65%' },
        },
      ],

      orderList: [
        // { orderID: '2053296302038546198' },
        // { orderID: '2052734736217546198' },
        // { orderID: '2051084463491546198' },
        // { orderID: '2049250443246546198' },
        // { orderID: '2049249183839546198' },
        // { orderID: '2049528578717546198' },
        // { orderID: '2048724805126546198' },
        // { orderID: '2048109422431546198' },
        // { orderID: '2047230732482546198' },
        // { orderID: '2046059402832546198' },
        // { orderID: '2045533011537546198' },
        // { orderID: '2045533335370546198' },
        // { orderID: '2045875045375546198' },
        // { orderID: '2045182801386546198' },
        // { orderID: '2044827411971546198' },
        // { orderID: '2043965967098546198' },
        // { orderID: '2044298461065546198' },
        // { orderID: '2042042881118546198' },
        // { orderID: '2041975130787546198' },
        // { orderID: '2042042773174546198' },
        // { orderID: '2037037728910546198' },
        // { orderID: '2037646994802546198' },
        // { orderID: '2035872374138546198' },
        // { orderID: '2035869998810546198' },
        // { orderID: '2035870538554546198' },
        // { orderID: '2035605795950546198' },
        // { orderID: '2035261272230546198' },
        // { orderID: '2034524388075546198' },
        // { orderID: '2034479028594546198' },
        // { orderID: '2035121953773546198' },
      ],
      orderListnew: [],
      NewOrderList: [],
      Interval: null,
      ref_key: null,
      resetdata: null,
      showOverOder: false,
      showPopup: false,
      popupMessage: '',
      runtrack_status: false,
    }
  },
  computed: {
    BaseWsUrl() {
      return this.$store.state.app.BaseWsUrl
    },
    WsMain() {
      return this.$store.state.app.WsMain
    },
    WsRuntrack() {
      return this.$store.state.app.WsSendRuntrack
    },
    fields() {
      return [
        {
          key: 'number',
          label: this.$t('number'),
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'username',
          label: 'username',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'password',
          label: 'password',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'type',
          label: 'website',
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'actions',
          label: this.$t('manage'),
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
  },
  async mounted() {
    // this.$bvModal.show('overlay-modal')
    // this.$bvModal.show('modal-runtrack-detail')
    this.CheckWsConnect()
    this.getData(this.search_val)
    this.RunTrackStatus()
  },

  created() {
    this.interval = setInterval(() => {
      this.RunTrackStatus()
    }, 1000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
  methods: {
    async RunTrackStatus() {
      try {
        const { data: res } = await this.$http.get('http://127.0.0.1:4567/runtrack_status')
        if (res) {
          this.runtrack_status = true
        }
      } catch (e) {
        this.runtrack_status = false
      }
    },
    async CheckWsConnect() {
      if (!this.WsMain) {
        await this.ConnectWS()
      }
      if (!this.WsRuntrack) {
        await this.SubWsOnRun()
        this.WsOnRuntrack()
      } else {
        this.WsOnRuntrack()
      }
    },
    SubWsOnRun() {
      const UserData = JSON.parse(localStorage.getItem('userData'))
      const WsChatRun = this.WsMain.subscribe(`Runtrack:${UserData.userID}`)
      // const WsChatRun = this.WsMain.subscribe('Runtrack:75')
      // this.WsChatfrist = this.WsMain.subscribe(`fristchat:${UserData.agent_id}`)
      this.$store.commit('app/SET_WSRUNTRACK', WsChatRun)
    },
    WsOnRuntrack() {
      console.debug('Start WsOnRuntrack')
      // this.WsRuntrack.on('checkaction', data => {
      //   console.debug('WsOnChat checkaction', data)
      // })
      // this.WsRuntrack.emit('message', 'HELLO')
      // this.WsChat.emit('cart', '')
      this.WsRuntrack.on('message', message => {
        console.debug('WsOnRuntrack mes:', message)

        // eslint-disable-next-line no-param-reassign
        if (message.runtrack === '1') {
          this.$bvModal.show('overlay-modal')
          this.popupMessage = message.message
        }
        if (message.runtrack === '2') {
          this.$bvModal.show('modal-runtrack-detail')
        }
        if (message.runtrack === '3') {
          this.orderListnew.push({ orderID: message.data })
        }
        if (message.runtrack === '4') {
          this.NewOrderList.push(message.data)
        }
      })
      this.WsRuntrack.on('error', error => {
        console.log('WsOnChat Error', error)
      })
    },
    async RunTrack(data) {
      try {
        this.orderListnew = []
        this.NewOrderList = []
        console.debug(data)
        const params = {
          // eslint-disable-next-line no-underscore-dangle
          ID: data._id,
          username: data.username,
          password: data.password,
        }
        this.$bvModal.show('overlay-modal')
        this.popupMessage = 'กรุณารอสักครู่...'
        if (data.type === '1688') {
          const { data: res } = await this.$http.get('runtrack/track1688', { params })
          if (res.success) {
            this.$bvModal.hide('overlay-modal')
          } else if (res.code === 'R01') {
            this.popupMessage = 'กรุณาล็อคอินเข้าสู่ระบบ'
            setTimeout(this.GetLogin(data), 2000)
          } else {
            this.$bvModal.hide('overlay-modal')
            this.SwalError(`${res.mes} code:${res.code}`)
          }
        } else {
          const { data: res } = await this.$http.get('runtrack/tracktaobao', { params })
          if (res.success) {
            this.$bvModal.hide('overlay-modal')
          } else if (res.code === 'R01') {
            this.popupMessage = 'กรุณาล็อคอินเข้าสู่ระบบ'
            setTimeout(this.GetLogin(data), 2000)
          } else {
            this.$bvModal.hide('overlay-modal')
            this.SwalError(`${res.mes} code:${res.code}`)
          }
        }
      } catch (err) {
        console.log(err)
        this.showOver = false
        this.$bvModal.hide('overlay-modal')
      }
    },
    async GetLogin(obj) {
      try {
        const { data: res } = await this.$http.post('http://127.0.0.1:4567/runtrack_auth', obj)
        if (res.success) {
          this.ReRunTrack(obj, res.cookies)
        } else {
          this.$bvModal.hide('overlay-modal')
          this.SwalError('เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง code:E09')
        }
      } catch (e) {
        this.$bvModal.hide('overlay-modal')
        this.SwalError('เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง code:E09')
        console.debug(e)
      }
    },
    async ReRunTrack(data, cookies) {
      try {
        console.debug(data)
        const params = {
          // eslint-disable-next-line no-underscore-dangle
          ID: data._id,
          username: data.username,
          password: data.password,
          cookies,
        }
        this.$bvModal.show('overlay-modal')
        this.popupMessage = 'กรุณารอสักครู่...'
        if (data.type === '1688') {
          const { data: res } = await this.$http.get('runtrack/track1688', { params })
          if (res.success) {
            this.$bvModal.hide('overlay-modal')
          } else {
            this.$bvModal.hide('overlay-modal')
            this.SwalError(`${res.mes} code:${res.code}`)
          }
        } else {
          const { data: res } = await this.$http.get('runtrack/tracktaobao', { params })
          if (res.success) {
            this.$bvModal.hide('overlay-modal')
          } else {
            this.$bvModal.hide('overlay-modal')
            this.SwalError(`${res.mes} code:${res.code}`)
          }
        }
      } catch (err) {
        console.log(err)
        this.showOver = false
        this.$bvModal.hide('overlay-modal')
      }
    },
    reload() {
      console.log('RELOAD')
    },
    time(data) {
      return moment(data).format('DD/MM/YYYY HH:mm:ss')
    },
    async getData() {
      this.showOver = true
      try {
        const params = {
          search_val: this.search_val,
          perPage: this.perPage,
          page: this.currentPage,
        }
        const { data: res } = await this.$http.get('/RunTrack/GetData', {
          params,
        })
        this.items = res.data
        this.totalRows = res.total
        this.showOver = false
        // console.debug(res)
      } catch (err) {
        console.log(err)
        this.SwalError(err)
        this.showOver = false
      }
    },
    submitAddNew() {
      this.$refs.addNewUser.validate().then(success => {
        if (success) {
          const obj = {
            id: this.id,
            type: this.typeweb,
            username: this.username,
            password: this.password,
          }
          this.$http
            .post('/RunTrack/UserStoreData', obj)
            .then(() => {
              this.getData()
              this.Success(this.$t('key-260'))
              this.$bvModal.hide('modal-add-new')
            })
            .catch(err => {
              console.log(err)
              this.SwalError(err)
            })
        }
      })
    },
    DeleteData(data) {
      this.$bvModal
        .msgBoxConfirm('คุณต้องการลบข้อมูลใช่หรือไม่?', {
          title: 'ยืนยันการลบข้อมูล',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            const obj = {
              id: data._id,
            }
            this.$http
              .post('/RunTrack/DeleteData', obj)
              .then(() => {
                this.getData()
                this.Success('ลบข้อมูลเรียบร้อย')
              })
              .catch(err => {
                console.log(err)
                this.SwalError(err)
              })
          }
        })
    },
    async downloadProgram() {
      try {
        this.showOver = true
        const response = await this.$http.get('runtrack/download_program', {
          responseType: 'blob', // Set response type to blob
        })
        this.showOver = false
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'AnanRunTrack_Setup.exe')
        document.body.appendChild(link)
        link.click()

        window.URL.revokeObjectURL(url)
        document.body.removeChild(link)
      } catch (error) {
        console.error('Error downloading program:', error)
      }
    },
    async RunTrack_bk(data) {
      try {
        this.showOver = true
        const params = {
          username: data.username,
          password: data.password,
          website: data.website,
          type: data.type,
          id: data._id,
        }
        if (data.type === 'Taobao, Tmall') {
          const { data: res } = await this.$http.get('runtrack/RuntrackTaobao', { params })
          this.resetdata = params
          if (res.status) {
            // this.orderListnew = res.data
            this.showOver = false
            // this.$bvModal.show('modal-runtrack-detail')
            // this.$bvModal.hide('overlay-modal')
          }
        } else {
          const { data: res } = await this.$http.get('runtrack/Runtrack1688', { params })
          this.resetdata = params
          if (res.status) {
            // this.orderListnew = res.data
            this.showOver = false
            // this.$bvModal.show('modal-runtrack-detail')
            // this.$bvModal.hide('overlay-modal')
          }
        }
      } catch (err) {
        console.log(err)
        // this.showOver = false
        // this.$bvModal.hide('overlay-modal')
      }
    },

    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAddNew(data) {
      if (data) {
        this.id = data._id
        if (data.type === '1688') {
          this.typeweb = 1
        }
        if (data.type === 'Taobao, Tmall') {
          this.typeweb = 2
        }
        this.username = data.username
        this.password = data.password
      } else {
        this.id = null
        this.typeweb = null
        this.username = null
        this.password = null
      }
      this.$bvModal.show('modal-add-new')
    },
  },
}
</script>

<style lang="scss" scoped>
#test___BV_modal_body_ {
  display: none !important;
}
</style>
